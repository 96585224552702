import React, { Component } from 'react';
import './App.scss';
import albumImageUrl from './images/tillian_lotus_graveyard.jpg';
import Blurbs from "./components/blurbs/Blurbs"
import Videos from "./components/videos/Videos"



class App extends Component {
  render() {
    return (
      <>
        <div className="main">
          <div className="cover">
            <img className="artCover"
                 alt="Tillian - Lotus Graveyard album cover"
                 src={albumImageUrl}/>
          </div>
          <div className="hero">
            <h1>Lotus Graveyard</h1>
            <h2>The new album from Tillian</h2>

            <h2 className="preorderTitle">Out Now!</h2>
            <div className="preorder">
                <a className="bandcamp" href="https://tillian.bandcamp.com/" title="Bandcamp">Bandcamp</a>
                <a className="spotify" href="https://open.spotify.com/album/4Of7zsoPj5JfBeHbtXL6Rt" title="Spotify">Spotify</a>
                <a className="apple" href="https://itunes.apple.com/us/album/lotus-graveyard/1450739447?app=itunes&ign-mpt=uo%3D4" title="Apple Music">Apple Music</a>
                <a className="deezer" href="https://www.deezer.com/en/album/85604022" title="Deezer">Deezer</a>
                <a className="tidal" href="https://tidal.com/browse/album/103098604" title="Tidal">Tidal</a>
                <a className="amazon" href="https://www.amazon.com/gp/product/B07N5HFHWJ" title="Amazon Music">Amazon Music</a>

            </div>
              
            <button className="cta" onClick={()=>window.showNewsletterModal()}>Join Tillian's newsletter</button>
          </div>
        </div>
        <div className="action"></div>
        <div className="footer">
            <div>
                <a className="facebook" href="http://facebook.com/tillianband">
                    <i className="fa fa-facebook"></i>
                    <span>Tillian on Facebook</span>
                </a>

                <a className="facebook" href="https://www.instagram.com/tillian.band/">
                    <i className="fa fa-instagram"></i>
                    <span>Tillian on Instagram</span>
                </a>

                <a className="youtube" href="https://www.youtube.com/channel/UCUeZ9WwgQiQq8ZtNWzU5geA">
                    <i className="fa fa-youtube"></i>
                    <span>Tillian on Youtube</span>
                </a>
                <div>
                    Interested in reviewing the album?<br />
                    Please contact <a href="mailto:press@tillian.banad">press@tillian.band</a>
                </div>
            </div>
        </div><Blurbs/>
        <Videos />
      </>
    );
  }
}

export default App;
