import React from "react";
import Slider from "react-slick";
import "./Blurbs.scss";

function Blurbs(){
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        pauseOnDotsHover: true,
        pauseOnHover: true,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className="blurbs">
            <div className="content">
                <h2>Reviews</h2>
                <Slider {...settings}>
                    <div>
                        <blockquote>
                            <span className="grade">9.5/10</span> - Wonderfully imaginative; The songwriting is crazy good
                            <footer><a href="http://www.metal-observer.com/3.o/review/tillian-lotus-graveyard-review/">The Metal Observer</a></footer>
                        </blockquote>
                    </div>
                    <div>
                        <blockquote>
                            If you listen to just one prog album this month, listen to this one
                            <footer><a href="http://www.heavymusichq.com/heavy-music-headquarters-reviews-week-of-april-26-2019/">Heavy Music Headquarters</a></footer>
                        </blockquote>
                    </div>
                    <div>
                        <blockquote>
                            <span className="grade">4.5/5</span> - Dynamic and forceful<br/>
                            <footer><a href="http://www.heavyblogisheavy.com/2019/04/17/tillian-lotus-graveyard">Heavy Blog is Heavy <span className="country">/ Israel</span></a></footer>
                        </blockquote>
                    </div>
                    <div>
                        <blockquote>
                            <span className="grade">8/10</span> - Lotus Graveyard captivates and proves that Tillian is capable of showing a great deal of personality, mastery, subtlety and variety<br/>
                            <footer><a href="https://www.coreandco.fr/chroniques/tillian-lotus-graveyard-7207.html">CoreAndCo <span className="country">/ France</span></a></footer>
                        </blockquote>
                    </div>
                    <div>
                        <blockquote>
                            Goes beyond conventions and combines the audacity of the first Queen with that of Symphony X
                            <footer><a href="http://progcritique.com/2019/04/tillian-lotus-graveyard/">Progcritique <span className="country">/ France</span></a></footer>
                        </blockquote>
                    </div>
                </Slider>
            </div>
        </section>
        )
}

export default Blurbs;