import React from "react";
import "./Videos.scss";

function Videos(){
    return (
        <section className="videos">
            <h2>Videos</h2>
            <div className="video">
                <div className="embed">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vgqOata9EL8"
                            frameBorder="0"
                            title="Frozen Sun"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="description">
                    <p>
                        Frozen Sun is the third single out of 'Lotus Graveyard'. It features a talented aerial silk acrobat and choreography that tells the story of struggle in times of pain and uncertainty. It is the dance of Samsara: Suffering, acceptance and finally transformation and rebirth.
                    </p>
                </div>
            </div>
            <div className="video">
                <div className="embed">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/pr9odekkRJw"
                            frameBorder="0"
                            title="Black Holes"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="description">
                    <p>
                        Taken from Lotus Graveyard's special release show, ‘Black holes’ is a progressive metal piece from the heavier side of the album with oriental and electronic influences. It tells of a dance with an inner demon and the need to find hope through personal struggle.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Videos;